import { createStore } from 'vuex'

const store = createStore({
  state: {
      isSignin: false
  },
  mutations: {
    signInState (state, bool) {
      state.isSignin = bool
    }
  }
})

export default store
