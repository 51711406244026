<script setup>
import { ref, onMounted, watch } from 'vue'
import axios from "axios"
import { useStore } from 'vuex'

const store = useStore()
const isSignin = ref(store.state.isSignin)
const email = ref('')

const issignin = async() => {    
  axios.get('/users', {
    headers: {
      'access_token': localStorage.getItem('access-token'),
      'client': localStorage.getItem('client'),
      'uid': localStorage.getItem('uid'),
    }
  })
  .then((r) => {
    email.value = r.data.data.email
    store.commit('signInState', true)
  })
  .catch((e) => {
    console.log(e)
  })
}

const logout = async() => {
  axios.delete('/auth/sign_out', {
    headers: {
      'access_token': localStorage.getItem('access-token'),
      'client': localStorage.getItem('client'),
      'uid': localStorage.getItem('uid'),
    }
  })
  .then(() => {
    localStorage.removeItem('access-token')
    localStorage.removeItem('client')
    localStorage.removeItem('uid')
    store.commit('signInState', false)
    console.log(store.state.isSignin)
  })
  .catch((e) => {
    console.log(e)
  })
}

watch(() => isSignin.value, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    issignin()
    console.log('henkou')
  }
  })
onMounted(issignin)
</script>
<template>
<div class="text-right" v-cloak>
  <div v-if="store.state.isSignin">
    <p>
      {{ email }}
        <button type="button" class="btn btn-danger m-3" @click='logout' >
            Signout
        </button>
    </p>
  </div>
  <div v-else>
    <router-link to="/signup">
      <button type="button" class="btn btn-primary m-3">
        SignUp
      </button>
    </router-link>
    <router-link to="/login">
      <button type="button" class="btn btn-primary m-3">
        Login
      </button>
    </router-link>
  </div>
</div>
</template>