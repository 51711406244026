
export default {
  routes: [
    {
      path: "/",
      name: "TopPage",
      component: () => import("@/views/TopPage.vue"),
      meta: {
        title: "top_page",
        desc: "top_page",
      }
    },
    {
      path: "/profile",
      name: "Profile",
      component: () => import("@/views/HelloWorld.vue")
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("@/views/CreateData.vue")
    },
    {
      path: "/signup",
      name: "Signup",
      component: () => import("@/views/auth/SignupPage.vue")
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/auth/LoginPage.vue")
    },
    {
      path: "/youtuber",
      name: "Youtuber",
      component: () => import("@/views/YoutuberPage.vue")
    },
    {
      path: "/comment_master",
      name: "CommentMaster",
      component: () => import("@/views/CommentMaster.vue")
    },
    {
      path: "/youtuber_comment",
      name: "YoutuberComments",
      component: () => import("@/views/YoutuberComments")
    }
  ]
};
