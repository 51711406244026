// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import App from './App'
import router from './router/index'
import axios from 'axios'
import { createApp } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faThumbsUp as regularThumbsUp } from '@fortawesome/free-regular-svg-icons'
import { faThumbsUp as solidThumbsUp } from '@fortawesome/free-solid-svg-icons'
import VueNextSelect from 'vue-next-select'
import 'vue-next-select/dist/index.min.css'
import store from './store/store'

library.add(regularThumbsUp, solidThumbsUp)

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
axios.defaults.baseURL = process.env.VUE_APP_API_URL
createApp(App).use(router).use(store).component('fa', FontAwesomeIcon).component('vue-select', VueNextSelect).mount("#app");
